<template>
  <v-container>
    <confirm-dialog
      :show="dialogDelete"
      :confirm-click="deleteUser"
      @close="closeConfirmationDialog"
    >
      Are you sure you want to delete this user?
      <p>This user no longer be able to access the platform.</p>
    </confirm-dialog>
    <v-card class="content-card-padding">
      <v-card-title class="pa-0 mb-6">
        <v-text-field
          v-model="pagination.search"
          clearable
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <download-excel
          :data="searchResult"
          :fields="exportDataFields"
          :meta="[]"
          name="download.xls"
        >
          <app-button
            :disabled="searchResult.length < 1"
            secondary
          >
            Export results
          </app-button>
        </download-excel>
      </v-card-title>
      <v-data-table
        :options.sync="pagination"
        :headers="headers"
        :items="users"
        :search="pagination.search"
        :custom-filter="customFilter"
        :no-data-text="noData"
        class="table-container"
        :footer-props="{
          itemsPerPageText: 'Users per page:',
          itemsPerPageOptions: pagination.rowsPerPageItems
        }"
      >
        <template
          v-if="isLoading.users"
          #body
        >
          <table-skeleton-loader :headers="headers" />
        </template>
        <template
          v-else
          #body="{ items }"
        >
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>

              <td v-if="item.emailVerified">
                <v-icon green>
                  check_circle
                </v-icon>
              </td>
              <td v-else>
                <v-icon>block</v-icon>
              </td>
              <td v-if="getUserUnit(item.unit)">
                {{ getUserUnit(item.unit) }}
              </td>
              <td v-else>
                <v-tooltip
                  bottom
                  :nudge-right="20"
                >
                  <template #activator="{ on }">
                    <span v-on="on">N/A<v-icon
                      class="info-icon"
                      small
                    >info</v-icon></span>
                  </template>
                  <span>Viewer users do not have Unit yet.</span>
                </v-tooltip>
              </td>
              <td>{{ displayRole(item.role) }}</td>
              <td>
                <v-menu
                  bottom
                  left
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-if="item.emailVerified">
                      <v-list-item
                        v-for="option in menuOptions"
                        :key="option.name"
                        @click="menuActions(option.name, item)"
                      >
                        {{ option.name }}
                      </v-list-item>
                    </template>
                    <v-list-item
                      v-else
                      @click="menuActions('resendEmail', item)"
                    >
                      Resend email verification
                    </v-list-item>
                    <v-list-item
                      @click="menuActions('deleteUser', item)"
                    >
                      Delete
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ConfirmDialog, Parse, TableSkeletonLoader } from '@kickbox/common-admin';
import { KICKBOX_ROLES } from '@kickbox/common-util';
import moment from 'moment';
import { config } from '@/utils';
import UserService from '@/services/userService';

export default {
  components: {
    ConfirmDialog,
    TableSkeletonLoader
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 12,
        rowsPerPageItems: [12, 24, 36, { text: 'All', value: -1 }],
        search: '',
      },
      selectedUser: null,
      dialogDelete: false,
      headers: [
        {
          text: 'Full name', value: 'name', align: 'left', width: 250
        },
        {
          text: 'Email', value: 'email', align: 'left', width: 200
        },
        {
          text: 'Email verified', value: 'emailVerified', align: 'left', width: 140
        },
        {
          text: 'Unit', value: 'unit.name', align: 'left', width: 200
        },
        {
          text: 'Role', value: 'role', align: 'left', width: 100
        },
        { sortable: false, width: 30 }
      ],
      menuOptions: [
        { name: 'View' },
        { name: 'Edit' }
      ],
      exportDataFields: {
        'Full name': 'name',
        Email: 'email',
        // workaround for: https://github.com/jecovier/vue-json-excel/issues/76
        'Email verified': { callback: (user) => (!!user.emailVerified).toString() },
        Unit: { callback: (user) => this.getUserUnit(user.unit) },
        Role: 'role',
        Tags: { callback: (user) => user.tags.map((t) => t.name) },
        'Created Date': { callback: (user) => moment(user.createdAt).format('MMM DD, YYYY') }
      }
    };
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'company'
    ]),
    users: {
      get() {
        return this.$store.getters.users;
      },
      set(users) {
        return users;
      }
    },
    noData() {
      return this.isLoading.users ? 'loading...' : 'No users.';
    },
    searchResult() {
      return this.users.filter((u) => this.customFilter(null, this.pagination.search, u));
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async () => {
      if (!from.name) {
        await UserService.getUsersPerCompany();
      }
    });
  },
  methods: {
    ...mapMutations([
      'removeUser',
    ]),
    getUserUnit(unit) {
      return (unit && unit.name) || null;
    },
    displayRole(role) {
      return role
        .filter((r) => r !== KICKBOX_ROLES.SERVICE_PROVIDER)
        .join(', ');
    },
    closeConfirmationDialog() {
      this.dialogDelete = false;
    },
    deleteUser() {
      this.closeConfirmationDialog();
      if (this.selectedUser) {
        UserService.deleteUser(this.selectedUser.id)
          .then(() => {
            this.removeUser(this.selectedUser);
            this.users = this.users.filter((user) => user.id !== this.selectedUser.id);
            this.selectedUser = null;
          })
          .catch((ex) => {
            this.$store.dispatch('showSnackBar', { text: ex.message });
          });
      }
    },
    resendEmailVerification(email) {
      this.$store.dispatch('showSnackBar', { text: 'A reminder about the email verification was sent.' });
      return Parse.Cloud.run('resendEmailVerification', { email });
    },
    menuActions(option, user) {
      switch (option) {
        case 'View':
          window.open(`${config.portalUrl}/#/user/${user.id}`, '_blank');
          break;
        case 'Edit':
          this.$router.push({ path: `manage/edit/${user.id}` });
          break;
        case 'resendEmail':
          this.resendEmailVerification(user.email);
          break;
        case 'deleteUser':
          this.dialogDelete = true;
          this.selectedUser = user;
          break;
        default:
          break;
      }
    },
    customFilter(value, search, item) {
      const customSearch = search ? search.toString().toLowerCase() : '';
      return item.email.toLowerCase().includes(customSearch)
        || item.role.toString().toLowerCase().includes(customSearch)
        || (item.unit && item.unit.name.toLowerCase().includes(customSearch))
        || item.name.toLowerCase().includes(customSearch);
    }
  }
};
</script>

<style scoped>
  .list__tile__title {
    text-align: center;
  }

  .dialog p {
    font-size: initial;
    font-weight: normal;
    margin-top: 20px;
  }

  table.table tbody td:last-of-type {
    padding: 0;
    text-align: right;
  }

  .list {
    min-width: 80px;
  }

  .info-icon {
    margin-left: 5px;
  }
</style>
