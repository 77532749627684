var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('confirm-dialog',{attrs:{"show":_vm.dialogDelete,"confirm-click":_vm.deleteUser},on:{"close":_vm.closeConfirmationDialog}},[_vm._v(" Are you sure you want to delete this user? "),_c('p',[_vm._v("This user no longer be able to access the platform.")])]),_c('v-card',{staticClass:"content-card-padding"},[_c('v-card-title',{staticClass:"pa-0 mb-6"},[_c('v-text-field',{attrs:{"clearable":"","append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.pagination.search),callback:function ($$v) {_vm.$set(_vm.pagination, "search", $$v)},expression:"pagination.search"}}),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.searchResult,"fields":_vm.exportDataFields,"meta":[],"name":"download.xls"}},[_c('app-button',{attrs:{"disabled":_vm.searchResult.length < 1,"secondary":""}},[_vm._v(" Export results ")])],1)],1),_c('v-data-table',{staticClass:"table-container",attrs:{"options":_vm.pagination,"headers":_vm.headers,"items":_vm.users,"search":_vm.pagination.search,"custom-filter":_vm.customFilter,"no-data-text":_vm.noData,"footer-props":{
        itemsPerPageText: 'Users per page:',
        itemsPerPageOptions: _vm.pagination.rowsPerPageItems
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(_vm.isLoading.users)?{key:"body",fn:function(){return [_c('table-skeleton-loader',{attrs:{"headers":_vm.headers}})]},proxy:true}:{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.email))]),(item.emailVerified)?_c('td',[_c('v-icon',{attrs:{"green":""}},[_vm._v(" check_circle ")])],1):_c('td',[_c('v-icon',[_vm._v("block")])],1),(_vm.getUserUnit(item.unit))?_c('td',[_vm._v(" "+_vm._s(_vm.getUserUnit(item.unit))+" ")]):_c('td',[_c('v-tooltip',{attrs:{"bottom":"","nudge-right":20},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v("N/A"),_c('v-icon',{staticClass:"info-icon",attrs:{"small":""}},[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v("Viewer users do not have Unit yet.")])])],1),_c('td',[_vm._v(_vm._s(_vm.displayRole(item.role)))]),_c('td',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[(item.emailVerified)?_vm._l((_vm.menuOptions),function(option){return _c('v-list-item',{key:option.name,on:{"click":function($event){return _vm.menuActions(option.name, item)}}},[_vm._v(" "+_vm._s(option.name)+" ")])}):_c('v-list-item',{on:{"click":function($event){return _vm.menuActions('resendEmail', item)}}},[_vm._v(" Resend email verification ")]),_c('v-list-item',{on:{"click":function($event){return _vm.menuActions('deleteUser', item)}}},[_vm._v(" Delete ")])],2)],1)],1)])}),0)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }